<template>
    <!--begin::Card-->
    <div class="card card-custom card-stretch">
        <!--begin::Header-->
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">
                    Informatii personale
                </h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1"
                    >Modifica informatiile personale</span
                >
            </div>
            <div class="card-toolbar">
                <button class="btn btn-success mr-2" @click="onSubmit">Salveaza</button>
                <button type="reset" class="btn btn-secondary" @click="onReset();addSpinner($event.target,300);">
                    Reset
                </button>
            </div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form">
            <!--begin::Body-->
            <div class="card-body">
                <div class="row">
                    <label class="col-xl-3"></label>
                    <div class="col-lg-9 col-xl-6">
                        <h5 class="font-weight-bold mb-6">Date Agent</h5>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Avatar</label>
                    <div class="col-lg-9 col-xl-6">
                        <div
                            class="image-input image-input-outline"
                            id="kt_profile_avatar"
                            :style="{ backgroundImage: `url(${photo_url})` }"
                            :class="inputChanged ? 'image-input-changed' : ''"
                        >
                            <div
                                class="image-input-wrapper"
                                :style="{ backgroundImage: `url(${photo_url})` }"
                            ></div>
                            <label
                                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                data-action="change"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Change avatar"
                            >
                                <i class="fa fa-pen icon-sm text-muted"></i>
                                <input
                                    type="file"
                                    name="profile_avatar"
                                    accept=".png, .jpg, .jpeg"
                                    @change="uploadFile"
                                    ref="file"
                                />
                                <input type="hidden" name="profile_avatar_remove" />
                            </label>
                            <span
                                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                data-action="cancel"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Cancel avatar"
                                @click="resetUpload"
                            >
                                <i class="ki ki-bold-close icon-xs text-muted"></i>
                            </span>
                        </div>
                        <span class="form-text text-muted"
                            >Va fi redimensionata la 50x50. PNG, JPG, JPEG acceptate.</span
                        >
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Prenume</label>
                    <div class="col-lg-9 col-xl-6">
                        <input
                            class="form-control form-control-lg form-control-solid"
                            type="text"
                            v-model="form.first_name"
                            name="firstName"
                            style=""
                        />
                        <span class="form-text text-primary"
                            >Acesta v-a aparea in semnatura.</span
                        >
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Nume</label>
                    <div class="col-lg-9 col-xl-6">
                        <input
                            class="form-control form-control-lg form-control-solid"
                            type="text"
                            v-model="form.last_name"
                            name="lastName"
                        />
                        <span class="form-text text-primary"
                            >Acesta v-a aparea in semnatura.</span
                        >
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Functie</label>
                    <div class="col-lg-9 col-xl-6">
                        <input
                            class="form-control form-control-lg form-control-solid"
                            type="text"
                            v-model="form.job_title"
                            name="jobTitle"
                        />
                        <span class="form-text text-primary"
                            >Acesta v-a aparea in semnatura.</span
                        >
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">NickName</label>
                    <div class="col-lg-9 col-xl-6">
                        <input
                            class="form-control form-control-lg form-control-solid"
                            type="text"
                            v-model="form.nick_name"
                            name="nickName"
                        />
                        <span class="form-text text-muted"
                            >Pentru o afisare mai sccurta in lista de utilizatori.</span
                        >
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Nr. tel</label>
                    <div class="col-lg-9 col-xl-6">
                        <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="la la-phone"></i>
                                </span>
                            </div>
                            <input
                                type="text"
                                class="form-control form-control-lg form-control-solid"
                                v-model="form.phone"
                                name="phone"
                                placeholder="Phone"
                            />
                        </div>
                        <span class="form-text text-primary"
                            >Acesta v-a aparea in semnatura.</span
                        >
                        <span class="form-text text-muted"
                            >Daca se doreste sa nu apara in semnatura lasati-l gol.</span
                        >
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Email</label>
                    <div class="col-lg-9 col-xl-6">
                        <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="la la-at"></i>
                                </span>
                            </div>
                            <input
                                type="text"
                                class="form-control form-control-lg form-control-solid"
                                name="email"
                                placeholder="Email"
                                v-model="form.email"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Body-->
        </form>
        <!--end::Form-->
    </div>
    <!--end::Card-->
</template>

<script>
import { mapActions } from "vuex";
import ApiService from "@/core/services/api.service";

export default {
    name: "UsersEditProfileCard",
    props: ["currentUser"],
    components: {},
    data() {
        return {
            form: {
                first_name: this.currentUser.firstName,
                last_name: this.currentUser.lastName,
                job_title: this.currentUser.jobTitle,
                nick_name: this.currentUser.nickName,
                phone: this.currentUser.phone,
                email: this.currentUser.email,
                status: this.currentUser.status,
                attachment_id:null,
            },
            photo_url: this.currentUser.photoUrl,
            inputChanged: false,
        };
    },
    mounted() {},
    computed: {},
    methods: {
        ...mapActions("users", ["getUsersData"]),
        onSubmit(event) {
            event.preventDefault();
            this.addSpinner(event.target);
            let userId = this.currentUser.id;
            let data = this.form;
            this.$store.dispatch("users/editUser", { userId, data })
            .then(() => {
                this.onReset();
                this.killSpinner(event.target);
            });
            this.inputChanged = false;
            // console.log(event);
        },
        onReset() {
            // Reset our form values
            this.getUsersData(this.$route.params.userId).then((response) => {
                this.form = {
                    first_name: this.currentUser.firstName,
                    last_name: this.currentUser.lastName,
                    job_title: this.currentUser.jobTitle,
                    nick_name: this.currentUser.nickName,
                    phone: this.currentUser.phone,
                    email: this.currentUser.email,
                    status: this.currentUser.status,
                    attachment_id: null,
                };
                this.photo_url= this.currentUser.photoUrl;
                this.inputChanged=false;
            });
        },
        resetUpload() {
            this.photo_url = this.currentUser.photoUrl;
            this.inputChanged = false;
            this.form.attachment_id = null;
        },
        uploadFile() {
            if(this.$refs.file.files) {
                var file = this.$refs.file.files[0];
                ApiService.postAttachments("attachments", file).then((response) => {
                    if(response) {
                        this.photo_url = response.fileUrl;
                        this.form.attachment_id = response.id;
                        this.inputChanged = true;
                    }
                });
            }
        }
    },
    created() {
        this.onReset();
    },
};
</script>
